
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "check-modal",
  components: { ElectronicSignature },
  emits: ["checkregistered"],
  props: {
    donorType: {
      type: String,
      default: "both"
    },
    donorId: Number,
    terminationReason: String
  },
  computed: {
    checkResultFiltered() {
      return this.terminationReasons.filter((obj) => {
        return (
          (obj as any).type.toLowerCase() ===
            (this.donorType as any).toLowerCase() ||
          (obj as any).type === "both"
        );
      });
    },
    canSendForm() {
      return this.checkResult === "";
    },
  },
  methods: {
    triggerSignatureForm() {
      let modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },

    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    storeFormDataWithSignature(signature) {
      ApiService.post("checks", {
        donorId: this.donorId,
        result: "cancel_others",
        termination_reason_id: this.checkResult,
        electronicSignature: signature,
        extra: this.observations,
      })
        .then(() => {
          let myModal = document.getElementById("check-modal");
          if (myModal) {
            myModal.click();
          }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
    storeCheck() {
      ApiService.post("checks", {
        donorId: this.donorId,
        result: "cancel_others",
        termination_reason_id: this.checkResult,
        extra: this.observations,
      })
        .then((response) => {
          let myModal = document.getElementById("check-modal");
          if (myModal) {
            myModal.click();
          }
          if (response.status === 202) {
            Swal.fire({
                title: "Solicitud Generada",
                text: "Se ha generado una solicitud de inactivación correctamente.",
                icon: "info",
                confirmButtonText: "Aceptar",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                },
            });
        }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
  },
  mounted() {
    ApiService.get("termination_reasons").then((response) => {
      this.terminationReasons = response.data;
    });
  },
  setup() {
    const donorCheckModal = ref<null | HTMLElement>(null);
    return { donorCheckModal };
  },
  data: () => {
    return {
      loading: false,
      observations: "",
      checkResult: "",
      errors: {},
      terminationReasons: [],
      checkResults: [
        {
          value: "cancel_voluntary",
          icon: "la-heart-broken",
          type: "both",
          label: "NO QUIERE",
        },
        {
          value: "cancel_moved",
          icon: "la-truck",
          type: "both",
          label: "SE MUDO",
        },
        {
          value: "cancel_miscarried",
          icon: "la-baby-carriage",
          type: "HCG",
          label: "INTERRUPCION DEL EMBARAZO",
        },
        {
          value: "cancel_deceased",
          icon: "la-ribbon",
          type: "both",
          label: "FALLECIO",
        },
        {
          value: "cancel_hcglowcount",
          icon: "la-chart-bar",
          type: "HCG",
          label: "BAJA POTENCIA",
        },
        {
          value: "cancel_others",
          icon: "la-thumbs-down",
          type: "both",
          label: "OTROS",
        },
      ],
    };
  },
});
